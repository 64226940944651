import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import _ from "lodash";

import './style.css';

import Config from '../../config';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "24px",
    width: "70%",
    margin: "auto",
  },
  a30: {
    width:'7%',
  },
  img30: {
    width:'100%',
  },
  img50: {
    width:'100%',
  },
  a50: {
    width:'30%',
  },

}));

const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();

  


  
  return (
    <header className={classes.root}>
          <a  className={classes.a30} href="/" target="_blank" >
           <img className={classes.img30} src="/img/coinmarketcap.png" />
          </a>
          <a  className={classes.a30} href="/" target="_blank" >
           <img className={classes.img30} src="/img/dex-screener.png" />
          </a>
          <a  className={classes.a50} href="/" >
           <img className={classes.img50} src="/logo.png" />
          </a>
          <a  className={classes.a30} href="/" target="_blank" >
           <img className={classes.img30} src="/img/x.png" />
          </a>
          <a  className={classes.a30} href="/" target="_blank" >
           <img className={classes.img30} src="/img/tg.png" />
          </a>      
    </header>
  );
}

export default Header;
