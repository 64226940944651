import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  darkTheme: true,
  modal: false,
  translator:{},
  locale: 'en',
   
};

export const appStoreSlice = createSlice({
  name: "appStore",
  initialState: initialState,
  reducers: {
    setData: (state, action) => ({ ...state, ...action.payload }),
  }
});

export const { setData } = appStoreSlice.actions;

export const getData = (state) => state.appStore;

export default appStoreSlice.reducer;

