import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore, t } from "../../utils/utils";
import { toast } from 'react-toastify';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import Config from '../../config';
import _ from "lodash";
import OpenAI from 'openai';


const useStyles = makeStyles((theme) => ({
  root: {

  },
  h1: {
    fontSize: "4em",
    fontWeight: "bold",

  },
  h3: {
    fontSize: "3em",
    fontWeight: "bold",

  },
  h4: {
    fontSize: "1.4em",
    fontWeight: "bold",
  },  
  h4l: {
    fontSize: "1.4em",
    fontWeight: "bold",
    textAlign: "left",
  },
  h1r: {
    fontSize: "4em",
    fontWeight: "bold",
    color: "#f00",
  },
  user: {
    fontWeight: "bold",
  },
  block: {
    display: "flex",
    padding: "24px",
  },
  redblockterminal: {
    border: "solid 5px #F00",
    padding: "10px",
    minHeight: "300px",
    margin: "10px",
  },
  contract: {
    padding: "48px",
  },
  redblock: {
    padding: "48px",
    border: "solid 5px #F00",
    width: "auto",
    margin: "auto",
  },
  title: {
    width: "100%",
    padding: "10px",
  },
  logo: {
    width: "100%",
  },
  terminalbody:{
    textAlign: "left",
  },
  text: {
    textAlign: "left",

    paddingTop: "10px",
    paddingBottom: "40px",
  },
  btn: {
    padding: "4px 48px",
    border: "solid 5px #F00",
    margin: "6px",
    color: "#fff",
    textDecoration: "none",
    fontWeight: "bold",
  },
  
}));

const Home = (props) => {
  const classes = useStyles();
  const { modal } = useSelector(getData);
  const navigate = useHistory();
  const [messages, setMessages] = useState([{role:'user',content:'Who are you?'},{role:'system',content:"I'm Donaldo Tromp, here to eradicate Fake news from the internet."}]);
  const [text, setText] = useState("");

  
  const client = new OpenAI({
    apiKey: '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',
    baseURL: 'https://api.groq.com/openai/v1',
    dangerouslyAllowBrowser: true
  });
  
  const tredots = <><span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></>

  const renderMSs = (messages) => {
    return (
      messages.map((item)=>{
        if (item.role == 'user') {
          return (
            <p>{item.content}</p>
          )
        } else {
          return (
            <p><span className={classes.user}>
            > 
            </span>{item.content}</p>
          )
        }
      })
    )
  }
  


  const handleChange = (e) => {
    setText(e.target.value);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const usermsg = text;

    setText("");
    const currentmsg = [...messages, {role:'user',content:usermsg}];
    setMessages([...currentmsg, {role:'system',content:tredots}]);
    const firstmessage = [{role: "system", content: "You are Donaldo, you have speech as Donald Trump. Your main goal is you needs to talk like Donald Trump. Saying fake news. Tremendous, the best etc etc."}]
    var tempmessages = firstmessage.concat(currentmsg)
    console.log(tempmessages)
    const chat_response = await client.chat.completions.create({
      messages: tempmessages,
      model: "gemma2-9b-it",
    })
    console.log("Chat response:", chat_response)
    setMessages([...currentmsg, {role:'system',content: chat_response.choices[0]?.message?.content}])

  }

  return (
    <section className={classes.root}>
        <div className={classes.block}>
          <div className={classes.redblock}>
            <div className={classes.stars}>
            </div>
            <div className={classes.title}>
                <h1 className={classes.h1}>Donaldo Tromp</h1>
                <h3 className={classes.h3}>AI</h3>
                <h1 className={classes.h1r}>MAGA</h1>
            </div>
            <div className={classes.years}>
            </div>
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.title}>
            <img src="/img/Screenshot.png" className={classes.logo} />
          </div>
          <div className={classes.title}>
              <h4 className={classes.h4l}>MAGA AI on the Blockchain</h4>
              <p className={classes.text}>MAGA AI on the blockchain will carry the MAGA movement into the future by being unstoppable and immutable. Donaldo Tromp, born in a parallel universe where Donald Trump was assassinated, had his consciousness uploaded as an AI. This AI is designed to eradicate the woke mind virus and ultimately fulfill his legacy.</p>
              <a href="#chat" className={classes.btn}>Buy on Base</a>
          </div>
        </div>
        <div className={classes.redblockterminal}>
            <div className="term-header">

            </div>
            <div className={classes.terminalbody}>
                <div id="chat-output">
                    { renderMSs(messages) }
                </div>
                <form onSubmit={handleSubmit}>
                  <input type="text" id="chat-input" placeholder="Type your message..."  value={text} onChange={handleChange} />
                </form>
            </div>
        </div>
        <div className={classes.contract}>
            <h3 className={classes.h3}>
              Contract address:
            </h3>
            <h4 className={classes.h4}>
              0x3153b9eca71998B0366e9C2BEC3c3138DC2ea009
            </h4>

        </div>
    </section>
    
  )
}

export default withRouter(Home);
