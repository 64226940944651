import { useCallback, useState } from "react";
import store from "../store";
import { setData } from "../store/appStoreSlice";

import Config from '../config';
import { toast } from 'react-toastify';





export const numberWithCommas = (value)=> value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const setStore = (payload) => {
  store.dispatch(setData(payload));
};

export const getStore = () => {
  return store.getState().appStore;
};


export const setReady = (inProcess) => {
  store.dispatch(setData({inProcess}));
}


export const minimize = (address) => {
    if (address) {
      return address.substring(0,6)+'...'+address.substring(address.length-4,address.length)
    } else {
      return "";
    }
}

export const t = (key) => {
  const { translator } =  getStore();
  
  if (translator[key]) {
    return translator[key];
  } else {
    return key
  }
}
