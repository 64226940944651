import React from "react";
import { makeStyles } from '@mui/styles';
import { t, setStore } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1em",
    fontWeight: "bold",
  },
  a30: {
    width:'12%',
  },
  img30: {
    width:'100%',
  },
  img50: {
    width:'30%',
  },
  a50: {
    width:'30%',
  },
  block: {

    padding: "24px",
  },
  blockimg: {
    width:'50%',
    padding: "24px",
    margin: "auto",
    display: "flex",
    justifyContent: "space-around",
  },
}));

const Footer = (props) => {
  const classes = useStyles();

  return (
    <footer  className={classes.root}>
      <div className={classes.block}>
           <img className={classes.img50} src="/logo.png" />
      </div>
      <div  className={classes.block}>
           donaldotromp.com
      </div>
      <div className={classes.blockimg}>
          <a  className={classes.a30} href="/" target="_blank" >
           <img className={classes.img30} src="/img/coinmarketcap.png" />
          </a>
          <a  className={classes.a30} href="/" target="_blank" >
           <img className={classes.img30} src="/img/dex-screener.png" />
          </a>

          <a  className={classes.a30} href="/" target="_blank" >
           <img className={classes.img30} src="/img/x.png" />
          </a>
          <a  className={classes.a30} href="/" target="_blank" >
           <img className={classes.img30} src="/img/tg.png" />
          </a>      
      </div>
      <p>Please be advised that Donaldo Tromp is a cryptocurrency created for entertainment purposes and is not affiliated with or endorsed by Donald J. Trump. While Donaldo Tromp may reference or incorporate elements related Trump’s persona, image, or likeness, it does not imply any direct endorsement, partnership, or approval by Trump. Any resemblance or association between Donaldo Tromp and Donald J. Trump is purely coincidental and intended for satirical or humorous purposes.

      </p>
    </footer>

  );
}

export default Footer;
